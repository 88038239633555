import React from 'react';
import { Row } from 'react-bootstrap';

interface FieldProps {
    label: string;
    id: string;
    type: string;
    placeholder: string;
    labelClass: string;
    inputClass: string;
    value: string;

    ref?: any;
    required?: boolean;
    disabled?: boolean;
    maxLength?: number;
    validCharCodes?: Array<number>;

    onBlur(e: any): void;
    onChange(e: any): void;
    onKeyPress?(e: any): void;
}

const Field: React.FC<FieldProps> = ({
    label,
    id,
    type,
    placeholder,
    labelClass,
    inputClass,
    value,
    ref = null,
    required = false,
    disabled = false,
    maxLength = null,
    validCharCodes = [],
    onChange,
    onBlur
}) => {
    const onKeyPress = (e: any) => {
        if (validCharCodes.length === 0) {
            return true;
        }

        // Assume no till we check.
        let allowed = false;
        for (let i = 0; i < validCharCodes.length; ++i) {
            if (e.charCode === validCharCodes[i]) {
                allowed = true;
                break;
            }
        }

        if (!allowed) {
            e.preventDefault();
        }
    };

    return (
        <Row>
            <label className={labelClass}>{label}</label>
            {
                type === "text" &&
                <input
                    data-testid={`input_field_${id}`}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    className={inputClass}
                    value={value}
                    ref={ref}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength ?? -1}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            }
        </Row>
    )
};

export default Field;
