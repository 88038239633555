import React from 'react';

interface SubmitButtonProps {
    processing: boolean;
    error: string;
    disabled: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ processing, error, children, disabled }) => (
    <button
        className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
        type='submit'
        disabled={processing || disabled}
    >
        {processing ? 'Processing...' : children}
    </button>
);

export default SubmitButton;
