import { Col, Row } from 'react-bootstrap';

import Skeleton from 'react-loading-skeleton';

const SkeletonTable: React.FC = () => (
    <Row className='mb-2'>
        <Col><Skeleton height={40} width={'100%'} count={1} delay={1} /></Col>
    </Row>
);

export default SkeletonTable;