import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../store';

interface SelectedEventProps {
    hideAbout?: boolean
}

interface SelectedData {
    event: any;
    cancelOrTransfer?: boolean;
}

const DisplayEventDetail: React.FC<SelectedData> = ({event, cancelOrTransfer}) => {
    return (
        <Fragment>
            <div className='border-top text-left'>
                <div style={{padding: "10px 30px", backgroundColor: "#FAF9FD"}} className={"noto"}>{event.eventTitle ?? event.title}</div>
            </div>
            <Card.Body style={{padding: "0px 13.5px"}} className='text-left'>
                <Row
                    data-testid='event_dates_row'
                    className={'noto'}
                    style={{padding: "8px 40px", borderTop: "1px solid #C4C6CF"}}
                >
                    <Col xs='12'>
                        <Row style={{alignItems: "center", padding: 10}}>
                            <Col xs='3'>
                                {moment(event.startDate).format('MMM DD, YYYY')} - {moment(event.endDate).format('MMM DD, YYYY')}
                            </Col>
                            <Col xs='3' className='text-center' style={{textTransform: 'capitalize'}}>
                                {event.city}, {event.country?.toLowerCase()}
                            </Col>
                            <Col xs='3' className='text-md-center text-left'>
                                {cancelOrTransfer ? (event.status) : (event.availableSeats + ' of ' + event.totalSeats + ' Seats Available')}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Fragment>
    )
}

const DisplayEventTypeDetail: React.FC<SelectedData> = ({event}) => {
    return (
        <Fragment>
            <Card.Body style={{padding: "0px 13.5px"}} className='text-left'>
                <Row
                    data-testid='event_dates_row'
                    className={'noto'}
                    style={{padding: "32px 40px"}}
                >
                    {event.description}
                </Row>
            </Card.Body>
        </Fragment>
    )
}

const SelectedEvent: React.FC<SelectedEventProps> = ({hideAbout = false}) => {
    const { eventSelected, cancelOrTransfer } = useSelector((state: RootState) => state.user);
    return (
        <Fragment>
            <Card
                data-testid='display_event_card'
                className='card-custom gutter-b'
                style={{marginBottom: 40}}
            >
                <Card.Header>
                    <div className='noto card-title font-weight-bolder text-left'>
                        { cancelOrTransfer ? 'Your current registration' : 'You are registering for' }
                    </div>
                </Card.Header>
                <DisplayEventDetail event={JSON.parse(eventSelected)} cancelOrTransfer={cancelOrTransfer}/>
            </Card>

            { !hideAbout &&
                <Card
                    data-testid='display_event_card'
                    className='card-custom gutter-b'
                    style={{marginBottom: 40}}
                >
                    <Card.Header>
                        <div className='noto card-title font-weight-bolder text-left pt-5 pb-5' style={{display: "flex", flexDirection: "column"}}>
                            <div style={{alignSelf: "baseline", fontSize: 12, color: "gray"}}>ABOUT</div>
                            {JSON.parse(eventSelected).title}
                        </div>
                    </Card.Header>
                    <DisplayEventTypeDetail event={JSON.parse(eventSelected)}/>
                </Card>
            }
        </Fragment>
    )
};

export default SelectedEvent;
