import { useAuth0 } from '@auth0/auth0-react';
import './Header.css'

import AppEmitter from '../../services/appEmitter';

const UserToggle: React.FC = () => {
    const { user } = useAuth0();

    const onShowUserPanel = () => {
        AppEmitter.emit('SHOW_USER_PANEL', true);
    };

    const getFirstName = (name: string) => {
        if (name) {
            if(name.split(' ')[0]) {
                return name.split(' ')[0];
            } else {
                return name;
            }
        }
    }

    const getInitials = (name : string) => {
        if (name.split(' ').length > 1) {
            return name.split(' ')[0].slice(0)[0] + '' + name.split(' ')[1].slice(0)[0];
        } else {
            return name.split(' ')[0].slice(0)[0]
        }
    }

    return (
        <div className='topbar-item'>
            <button
                className='btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2'
                id='kt_quick_user_toggle'
                data-testid='user_toggle'
                onClick={() => onShowUserPanel()}>
                <div className='d-flex flex-column text-right pr-sm-3'>
                    <span className='text-primary font-weight-bold font-size-sm d-none d-sm-inline noto'>Hey, {getFirstName(user?.name ?? "")}</span>
                </div>
                { (user?.name != "") &&
                    <div className="circle noto">{getInitials(user?.name ?? "")}</div>
                }
            </button>
        </div>
    );
};

export default UserToggle;
