import { Card } from 'react-bootstrap';

import Skeleton from 'react-loading-skeleton';
import SkeletonTable from './SkeletonTable';

const SkeletonCard: React.FC = () => (
    <Card className='card-custom gutter-b'>
        <Card.Header>
            <div className='text-left card-title font-weight-bolder'>
                <div className='card-label'>
                    <Skeleton height={25} width={250} count={1} delay={1} /><br />
                </div>
            </div>
        </Card.Header>
        <Card.Body className='text-left'>
            <SkeletonTable />
        </Card.Body>
    </Card>
);

export default SkeletonCard;