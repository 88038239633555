interface LoadingProps {
    height?: number;
    width?: number;
}

const Loading: React.FC<LoadingProps> = ({ height, width }) => (
    <div style={{marginTop: "25%"}}>
        <div className="spinner-border text-primary" style={{width: 100, height: 100}} role="status">
         <span className="sr-only">Loading...</span>
        </div>
    </div>
);

export default Loading;
