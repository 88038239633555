import React, { Fragment, useEffect } from "react";
import { Card } from "react-bootstrap";

import "./UserAgreement.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { setEventSelected, setRequestSuccess } from "../../store/User/UserSlice";

interface Props {
    onAgree?(e: any): void;
    agreed?: boolean;
}

const SuccessCard: React.FC<Props> = ({ onAgree, agreed }) => {
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const { requestType } = useSelector((state: RootState) => state.user);

    useEffect(()=>{
    },[requestType])

    return (
        <Fragment>
            <Card
                data-testid='display_event_card'
                className='card-custom gutter-b'
                style={{marginBottom: 30}}
            >
                <Card.Header>
                    <div className='noto card-title font-weight-bolder text-left'>
                        Your {requestType?.toLowerCase()} request has been submitted.
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='noto card-title text-left'>
                        Someone will contact you at {user?.email} to confirm your request.
                    </div>
                    <button
                        data-testid='start_register_button'
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {dispatch(setEventSelected("")); dispatch(setRequestSuccess(false))}}
                    >Return to Home Page</button>
                </Card.Body>
            </Card>
        </Fragment>
    )
};

export default SuccessCard;