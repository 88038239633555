import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { Loading } from '../components';

const Logout: React.FC = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        logout({
            returnTo: process.env.REACT_APP_ROOT_URL
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Loading />
    )
};

export default Logout;
