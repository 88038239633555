import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '../store';
import { fetchCurrentHolds, setAgreementAccepted, setCurrentEventType } from '../store/User/UserSlice';
import { AvailableRegistrations, CurrentRegistrations, PendingRegistrations } from '.';
import SelectedEvent from './SelectedEvent';
import UserAgreement from './checkout/UserAgreement';
import TransferCancelDetails from './TransferCancelDetails';
import { addHold } from '../services/apiService';
import { Event } from '../@types/event.d';
import SuccessCard from './checkout/SuccessCard';


const Home: React.FC = () => {
    const { user } = useAuth0();
    const { currentHolds, eventSelected, proceededToPayment, cancelOrTransfer, requestType, requestSuccess } = useSelector((state: RootState) => state.user);
    const [mode, setMode] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [mode, eventSelected]);

    useEffect(() => {
        if (proceededToPayment) {
            startRegister();
        } else if (cancelOrTransfer) {
            setMode(2);
        } else {
            setMode(0);
        }
    },[proceededToPayment, cancelOrTransfer])

    useEffect(() => {
        if (eventSelected && proceededToPayment && currentHolds && currentHolds.length > 0) {
            // console.log(currentHolds)
            setMode(1);
        }
    },[currentHolds])

    const startRegister = async () => {
        const event = JSON.parse(eventSelected) as Event;
        if (user) {
            var acctId = user['http://schemas.ccl.org/accounts/claims/account/id'];
            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']

            let success = await addHold(acctId, clientId, event.eventKey);
            if (success) {
                // Refresh current holds
                dispatch(setCurrentEventType(event.programCode));
                dispatch(fetchCurrentHolds(user));
            }
        }
    }

    if (eventSelected === "") {
        return (
            <Fragment>
                <CurrentRegistrations />
                <AvailableRegistrations />
            </Fragment>
        )
    } else {if (mode===0) {
        return (
            <Fragment>
                <SelectedEvent />
                <TransferCancelDetails />
                <UserAgreement onAgree={(toggle) => {dispatch(setAgreementAccepted(toggle))}} /> 
            </Fragment>
        )
    } else if (mode===2) {
           return (
            <Fragment>
                <SelectedEvent hideAbout={requestSuccess}/>
                {
                    requestSuccess ?
                    <SuccessCard/>
                    :
                    <Fragment>
                        <UserAgreement agreed={true} /> 
                        <TransferCancelDetails displayForm={true} onAgree={(toggle) => {dispatch(setAgreementAccepted(toggle))}}/>
                    </Fragment>
                }
                
            </Fragment>
        )
    } else {
        return (
            <PendingRegistrations/>
        )
    }}
};

export default Home;
