import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../@types/user.d';
import { Registration } from '../../@types/registration.d';
import { Hold } from '../../@types/hold.d';
import { EventType } from '../../@types/eventType.d';
import { getEventsByClient, getCurrentHolds, getRegistrations } from '../../services/apiService';
import { checkEventsCutoff } from '../../utils/checkEventsCutoff';
import { Event } from '../../@types/event.d';

export const fetchCurrentRegistrations = createAsyncThunk('currentRegistrations.fetch', async (user: any) => {
    try {
        if (user) {
            var acctId = user['http://schemas.ccl.org/accounts/claims/account/id'];
            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
            return getRegistrations(acctId, clientId);
        }
    } catch (error) {
        return Promise.reject(error);
    }
});

export const fetchCurrentHolds = createAsyncThunk('currentHolds.fetch', async (user: any) => {
    try {
        if (user) {
            var acctId = user['http://schemas.ccl.org/accounts/claims/account/id'];
            return getCurrentHolds(acctId);
        }
    } catch (error) {
        return Promise.reject(error);
    }
});

export const fetchEventTypes = createAsyncThunk('events.fetch', async (user: any) => {
    try {
        if (user) {
            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
            let cohort = ""
            //Cohort Based Mapping Temporarily Removed
            //user['http://schemas.ccl.org/accounts/claims/client/cohort']
            return getEventsByClient(clientId, cohort, true);
        }
    } catch (error) {
        return Promise.reject(error);
    }
});

const UserSlice = createSlice({
    name: 'UserSlice',
    initialState: {
        userProfile: {} as User,
        registrations: [] as Array<Registration>,
        currentHolds: [] as Array<Hold>,
        eventTypes: [] as Array<EventType>,
        currentEventType: '',
        eventSelected: "",
        gradeSelected: "",

        // Initially, everything is loading.
        registrationsLoading: true,
        holdsLoading: true,
        eventsLoading: true,

        registrationsRefreshing: false,
        holdsRefreshing: false,
        eventsRefreshing: false,

        agreementAccepted: false,
        proceededToPayment: false,
        cancelOrTransfer: false,

        requestType: "",
        requestNotes: "",
        requestSuccess: false,
    },
    reducers: {
        setCurrentHold(state, action: PayloadAction<string>) {
            state.currentHolds = JSON.parse(action.payload) as Array<Hold>;
        },
        setCurrentEventType(state, action: PayloadAction<string>) {
            state.currentEventType = action.payload;
        },
        setEventSelected(state, action: PayloadAction<string>) {
            state.eventSelected = action.payload;
            if (action.payload === "") {
                state.agreementAccepted = false;
                state.proceededToPayment = false;
                state.cancelOrTransfer = false;
                state.requestType = "";
                state.requestNotes = "";
                state.requestSuccess = false;
            }
        },
        setAgreementAccepted(state, action: PayloadAction<boolean>) {
            state.agreementAccepted = action.payload;
        },
        setProceededToPayment(state, action: PayloadAction<boolean>) {
            state.proceededToPayment = action.payload;
        },
        setCancelOrTransfer(state, action: PayloadAction<boolean>) {
            state.cancelOrTransfer = action.payload;
        },
        setRequestType(state, action: PayloadAction<string>) {
            state.requestType = action.payload;
        },
        setRequestNotes(state, action: PayloadAction<string>) {
            state.requestNotes = action.payload;
        },
        setRequestSuccess(state, action: PayloadAction<boolean>) {
            state.requestSuccess = action.payload;
        },
        setGradeSelected(state, action: PayloadAction<string>) {
            state.gradeSelected = action.payload;
        },
    },
    extraReducers: builder => {
        // User registrations Get API Status Reducers
        builder.addCase(fetchCurrentRegistrations.pending, state => {
            state.registrationsRefreshing = true;
        });

        builder.addCase(fetchCurrentRegistrations.fulfilled, (state, action) => {
            state.registrations = action.payload as Array<Registration>
            // state.registrations = [{
            //     title: "GSK Leading Leaders Programme – Directors",
            //     eventKey: "212658",
            //     description: "",
            //     startDate: "2025-02-25T00:00:00",
            //     endDate: "2025-02-27T00:00:00",
            //     status: "Confirmed"
            // }] as Array<Registration>; //for testing only
            state.registrationsLoading = false;
            state.registrationsRefreshing = false;
        });

        builder.addCase(fetchCurrentRegistrations.rejected, (state, action) => {
            console.error(`Failed to fetch current registrations, HTTP Status Code: ${action.error.message}`);
            state.registrationsLoading = false;
            state.registrationsRefreshing = false;
        });

        // User holds Get API Status Reducers
        builder.addCase(fetchCurrentHolds.pending, state => {
            state.holdsRefreshing = true;
        });

        builder.addCase(fetchCurrentHolds.fulfilled, (state, action) => {
            state.currentHolds = action.payload as Array<Hold>
            state.holdsLoading = false
            state.holdsRefreshing = false;
        });

        builder.addCase(fetchCurrentHolds.rejected, (state, action) => {
            console.error(`Failed to fetch current holds, HTTP Status Code: ${action.error.message}`);
            state.holdsLoading = false;
            state.holdsRefreshing = false;
        });

        // Events Get API Status Reducers
        builder.addCase(fetchEventTypes.pending, state => {
            state.eventsRefreshing = true;
        });

        builder.addCase(fetchEventTypes.fulfilled, (state, action) => {
            state.eventTypes = checkEventsCutoff(action.payload as Array<EventType>);
            state.eventsLoading = false;
            state.eventsRefreshing = false;
        });

        builder.addCase(fetchEventTypes.rejected, (state, action) => {
            console.error(`Failed to fetch events, HTTP Status Code: ${action.error.message}`);
            state.eventsLoading = false;
            state.eventsRefreshing = false;
        });
    }
});

export const { 
    setCurrentHold, 
    setCurrentEventType, 
    setEventSelected, 
    setAgreementAccepted, 
    setProceededToPayment,
    setCancelOrTransfer,
    setRequestType,
    setRequestNotes,
    setRequestSuccess,
    setGradeSelected,
} = UserSlice.actions

export default UserSlice.reducer