import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'

import { useAuth0, } from '@auth0/auth0-react';

import { Footer, Header, Loading, MobileHeader, ScrollTop, UserPanel } from './components';
import { Dashboard, Logout, NotFound, WhoAmI } from './pages';
import { RemoteError } from './@types/grandCentral.d';

import { Container } from 'react-bootstrap';

export const MainPage = () => {
    const search = useLocation().search;

    const error = new URLSearchParams(search).get('error');
    const errorDescription = new URLSearchParams(search).get('error_description');
    const [errorMessage, setErrorMessage] = useState<string>();
    const {
        isLoading,
        isAuthenticated,
        logout,
        user
    } = useAuth0();

    useEffect(() => {
        if (error && error === "unauthorized") {
            var remoteErrorNoAccess = JSON.parse(errorDescription as string) as RemoteError;
            setErrorMessage(`message=Message contains error: 'unauthorized', error_description: '${JSON.stringify(remoteErrorNoAccess)}', error_uri: 'error_uri is null'.`)
        } else {
            if (isAuthenticated) {
                var GCUser = user as any;
                if (GCUser) {
                    var GCuserGroups = GCUser["http://schemas.ccl.org/accounts/claims/group/ids"];
                    if (!GCuserGroups || GCuserGroups.split(',').length === 0 || !GCuserGroups.split(',').includes(process.env.REACT_APP_GROUPID)) {
                        var errorObject = {
                            resultCode: "user_not_found",
                            message: "Your account doesn't have access to this system."
                        };
                        var remoteError = JSON.parse(JSON.stringify(errorObject)) as RemoteError;
                        setErrorMessage(`message=Message contains error: 'unauthorized', error_description: '${JSON.stringify(remoteError)}', error_uri: 'error_uri is null'.`)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [user, isAuthenticated, error])

    useEffect(() => {
        if (errorMessage) {
            logout({
                returnTo: `https://accounts.ccl.org/error?message=${encodeURI(errorMessage as string)}`
            });
        }
        // eslint-disable-next-line
    }, [errorMessage])

    if (isLoading) return <Loading />;
    if (errorMessage) return null;
    return (
        <Router>
            <div className='d-flex flex-column flex-root'>
                <div className='d-flex flex-row flex-column-fluid page'>
                    <div className='d-flex flex-column flex-row-fluid wrapper' id='kt_wrapper'>
                        <div className='content d-flex flex-column flex-column-fluid mb-5' id='kt_content'>
                            <Fragment>
                                {
                                    isAuthenticated && <Header />
                                }
                                <MobileHeader />
                                <UserPanel />
                                <Container>
                                    <Switch>
                                        <Route exact path={['/', '/event', '/request']}>
                                            <Dashboard />
                                        </Route>
                                        <Route exact path='/whoami'>
                                            <WhoAmI />
                                        </Route>
                                        <Route exact path='/logout'>
                                            <Logout />
                                        </Route>
                                        <Route>
                                            <NotFound />
                                        </Route>
                                    </Switch>
                                </Container>
                                {
                                    isAuthenticated && <Footer />
                                }
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default MainPage;
