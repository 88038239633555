import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import moment from 'moment';

import { useAuth0 } from "@auth0/auth0-react";
import { PaymentIntentResult, StripeCardElement } from "@stripe/stripe-js";
import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";

import Field from "./Field";
import UserAgreement from "./UserAgreement";
import SubmitButton from "./SubmitButton";

import { RootState } from "../../store";

import { createPaymentIntent, getEventByKey, validateHold } from "../../services/apiService";
import { getCountries, getStates } from "../../services/dataService";
import { Event } from '../../@types/event.d';

import "./CheckoutForm.css";
import { setEventSelected } from "../../store/User/UserSlice";
import { getAnalytics, logEvent } from "firebase/analytics";

const ErrorMessage: React.FC = ({ children }) => (
    <div className="ErrorMessage" role="alert" style={{width: "100%", marginTop: 5}}>
        {children}
    </div>
);

interface CheckoutFormProps {
    onProcessing(): void;
    onSuccess(holdId: string): void;
    onError(): void;
    onCancel(): void;
    onComplete(): void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onProcessing, onSuccess, onError, onCancel, onComplete }) => {
    const { currentHolds } = useSelector((state: RootState) => state.user);
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const [event, setEvent] = useState<Event>({} as Event);
    const [countries] = useState<Array<any>>(getCountries());
    const [states, setStates] = useState<Array<any>>([]);
    const [countryId, setCountryId] = useState<string>("");
    const [areaCodeLabel, setAreaCodeLabel] = useState<string>("Postal Code*");

    const [error, setError] = useState<any>(null);
    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [paymentIntentResult, setPaymentIntentResult] = useState<PaymentIntentResult>({} as PaymentIntentResult);
    const [billingDetails, setBillingDetails] = useState({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: ""
    });

    const [billingErrors, setBillingErrors] = useState({
        firstName: false,
        lastName: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        postalCode: false,
        country: false,
        card: false
    });

    const countryInputRef = useRef<any>();
    const stateInputRef = useRef<any>();

    useEffect(() => {
        async function loadEvent(eventKey: string, clientId: string) {
            let evt = await getEventByKey(eventKey, clientId);
            setEvent(evt);
        }

        if (user && currentHolds && currentHolds.length > 0) {
            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
            loadEvent(currentHolds[0].eventKey, clientId);
        }
        // eslint-disable-next-line
    }, [currentHolds]);

    useEffect(() => {
        if (countryId === "USA") {
            setAreaCodeLabel("Zip Code*");
        } else {
            setAreaCodeLabel("Postal Code*");
        }
    }, [countryId]);

    const validate = (field: string, x: string) => {
        switch(field) {
            case "name": return x.trim().length > 0 //return /^[A-Za-z ]+$/.test(x) && x.length >= 2;
            case "address": return x.trim().length > 0 //return x.length > 10;
            case "city": return x.trim().length > 0 // /^[A-Za-zÀ-ÿ .'-]+$/.test(x) && x.length > 2;
            case "zip": return x.trim().length > 0 // /^(?:\d{5}(-\d{4})?|\d{6}|\w{1}\d{1}\w{1}\s\d{1}\w{1}\d{1}|[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i.test(x);
        }
    }

    const hasErrors = () => {
        return billingErrors.firstName
            ||
            billingErrors.lastName
            ||
            billingErrors.address1
            ||
            billingErrors.address2
            ||
            billingErrors.city
            ||
            billingDetails.country === ""
            ||
            (billingDetails.country === "US" && billingDetails.state === "")
            ||
            (billingDetails.country === "CA" && billingDetails.state === "")
            ||
            billingErrors.postalCode
            ||
            !cardComplete;
    }

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (hasErrors()) {
            return;
        }

        if (error) {
            elements?.getElement("card")?.focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
            onProcessing();
        }

        // Validate the hold before proceeding.
        let clientId = user ? user["http://schemas.ccl.org/accounts/claims/client/id"] : "";
        let stillValid = await validateHold(currentHolds[0].accountId, clientId, currentHolds[0].eventKey);

        if (!stillValid) {
            setError({
                message: "We are sorry, but we cannot complete this registration. Please cancel and select another date."
            });
            onError();
        } else {
            try {
                // Call our API to get a payment intent...
                let intentRequest = {
                    currency: "usd",
                    holdId: currentHolds[0].holdId,
                    email: user?.email as string,
                    environment: process.env.REACT_APP_ENVIRONMENT as string,
                    eventKey: currentHolds[0].eventKey,
                    imKey: user ? user["http://schemas.ccl.org/accounts/claims/ccl-identity/id"] : 0,
                    countryId: countryId,
                    sessionId: event.sessionId,
                    billingDetails: {
                        name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                        address1: billingDetails.address1,
                        address2: billingDetails.address2,
                        city: billingDetails.city,
                        state: billingDetails.state,
                        postalCode: billingDetails.postalCode,
                        country: billingDetails.country
                    },
                    programCode: event.programCode,
                    projectId: event.projectId,
                    description: `Course Paid for ${billingDetails.firstName} ${billingDetails.lastName} : ${event.programCode} - ${event.eventTitle} (${moment(event.startDate).format('MMM DD, YYYY')} - ${moment(event.endDate).format('MMM DD, YYYY')})`,
                    clientId: clientId
                };
                let paymentIntent = await createPaymentIntent(currentHolds[0].accountId, intentRequest);

                // Confirm the card payment with Stripe
                const confirmPaymentResult = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement) ?? {} as StripeCardElement,
                        billing_details: {
                            name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                            email: user?.email as string,
                            address: {
                                line1: billingDetails.address1,
                                line2: billingDetails.address2,
                                city: billingDetails.city,
                                state: billingDetails.state,
                                postal_code: billingDetails.postalCode,
                                country: billingDetails.country
                            }
                        }
                    }
                });

                if (confirmPaymentResult.error) {
                    //Overrite the message with a friendly message
                    confirmPaymentResult.error.message = "There was a problem processing your card payment. Please submit an email to GSK-CCLParticipantSupport@ccl.org for additional assistance. " + confirmPaymentResult.error.message;
                    setError(confirmPaymentResult.error);
                    onError();
                } else {
                    setPaymentIntentResult(confirmPaymentResult);
                    onSuccess(currentHolds[0].holdId);

                    logEvent(getAnalytics(),
                        "Registered for Event",
                        {
                            "Event Name": event.title,
                            "Event ID / Code": event.programCode + " (" + event.eventKey + ")",
                            "Event Location": (event.city + ", " + event.country),
                            "User": user ? user['http://schemas.ccl.org/accounts/claims/account/analytics-id'] : "tracking_id"
                        }
                    );
                }
            } catch (error) {
                console.error(error);
                setError(error);
                onError();
            }
        }

        setProcessing(false);
    };

    return JSON.stringify(paymentIntentResult) !== "{}" ? (
        <div className="col-12 Result">
            <div className="ResultMessage">
                Your Payment Has Been Accepted.
            </div>
            <div className="ResultMessage">
                Your registration is being processed and will appear on your dashboard soon.
            </div>
            <button
                data-testid='start_register_button'
                className='btn btn-outline-primary btn-sm'
                onClick={() => dispatch(setEventSelected(""))}
            >Return to Home Page</button>
        </div>
    ) : (
        <Fragment>
            <span className="m-5 font-size-h6 font-weight-bold">Your payment information:</span>
            <form className="Form noto" onSubmit={handleSubmit} style={{ width: "100%" }}>
                <fieldset className='FormGroup'>
                    <Row className="g-3 FormRow">
                        <Col md="12">
                            <Row className="pt-1 pb-1">
                                <Col md="2">
                                    <label className="form-label">Country*</label>
                                </Col>
                                <Col md="10" style={{ paddingLeft: "0" }}>
                                    <Select
                                        data-testid={`select_field_country`}
                                        id="country"
                                        placeholder="Select..."
                                        ref={countryInputRef}
                                        options={countries}
                                        required
                                        onBlur={(e: any) => { }}
                                        onChange={(e: any) => {
                                            setError(null);
                                            if (e) {
                                                setBillingDetails({
                                                    ...billingDetails,
                                                    state: "",
                                                    country: e.value
                                                });
                                                setBillingErrors({
                                                    ...billingErrors,
                                                    state: false,
                                                    country: false
                                                });
                                            }

                                            if (stateInputRef?.current) {
                                                stateInputRef.current.select.clearValue();
                                            }

                                            let cid = countries.filter(x => x.value === e.value)[0].id;
                                            setCountryId(cid);

                                            let sts = getStates(e.value);
                                            setStates(sts);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="g-3 FormRow">
                        <Col md="6">
                            <Field
                                label="Full name*"
                                id="firstName"
                                type="text"
                                placeholder="First name"
                                required
                                labelClass={"col-md-4 form-label" + (billingErrors.firstName ? " has-error" : "")}
                                inputClass={"col-md-8 form-control" + (billingErrors.firstName ? " has-error" : "")}
                                value={billingDetails.firstName}
                                onBlur={(e: any) => {
                                    setBillingDetails({ ...billingDetails, firstName: e.target.value.trim() });
                                }}
                                onChange={(e: any) => {
                                    setError(null);
                                    setBillingDetails({ ...billingDetails, firstName: e.target.value });
                                    if(validate("name",e.target.value.trim())) {
                                        setBillingErrors({ ...billingErrors, firstName: false });
                                    } else {
                                        setBillingErrors({ ...billingErrors, firstName: true });
                                    }
                                }}
                            />
                        </Col>
                        <Col md="6">
                            <Field
                                label=""
                                id="lastName"
                                type="text"
                                placeholder="Last name"
                                required
                                labelClass={"col-md-1 form-label" + (billingErrors.lastName ? " has-error" : "")}
                                inputClass={"col-md-11 form-control" + (billingErrors.lastName ? " has-error" : "")}
                                value={billingDetails.lastName}
                                onBlur={(e: any) => {
                                    setBillingDetails({ ...billingDetails, lastName: e.target.value.trim() });
                                }}
                                onChange={(e: any) => {
                                    setError(null);
                                    setBillingDetails({ ...billingDetails, lastName: e.target.value });
                                    if(validate("name",e.target.value.trim())) {
                                        setBillingErrors({ ...billingErrors, lastName: false });
                                    } else {
                                        setBillingErrors({ ...billingErrors, lastName: true });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="g-3 FormRow">
                        <Col>
                            <Field
                                label="Address*"
                                id="address1"
                                type="text"
                                placeholder="Address line 1"
                                required
                                labelClass={"col-md-2 form-label" + (billingErrors.address1 ? " has-error" : "")}
                                inputClass={"col-md-10 form-control" + (billingErrors.address1 ? " has-error" : "")}
                                value={billingDetails.address1}
                                onBlur={(e: any) => {
                                    setBillingDetails({ ...billingDetails, address1: e.target.value.trim() });
                                }}
                                onChange={(e: any) => {
                                    setError(null);
                                    setBillingDetails({ ...billingDetails, address1: e.target.value });
                                    if(validate("address",e.target.value.trim())) {
                                        setBillingErrors({ ...billingErrors, address1: false });
                                    } else {
                                        setBillingErrors({ ...billingErrors, address1: true });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        states.length > 0
                        &&
                        <Row className="g-3 FormRow no-border">
                            <Col>
                                <Field
                                    label=""
                                    id="address2"
                                    type="text"
                                    placeholder="Address line 2 (optional)"
                                    required={false}
                                    labelClass={"col-md-2 form-label"}
                                    inputClass={"col-md-10 form-control"}
                                    value={billingDetails.address2}
                                    onBlur={(e: any) => {
                                        setBillingDetails({ ...billingDetails, address2: e.target.value.trim() });
                                    }}
                                    onChange={(e: any) => {
                                        setError(null);
                                        setBillingDetails({ ...billingDetails, address2: e.target.value });
                                        setBillingErrors({ ...billingErrors, address2: false });
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                    <Row className="g-3 FormRow">
                        <Col md="12">
                            <Field
                                label="City*"
                                id="city"
                                type="text"
                                placeholder="City"
                                labelClass={"col-md-2 form-label" + (billingErrors.city ? " has-error" : "")}
                                inputClass={"col-md-10 form-control" + (billingErrors.city ? " has-error" : "")}
                                value={billingDetails.city}
                                required
                                onBlur={(e: any) => {
                                    setBillingDetails({ ...billingDetails, city: e.target.value.trim() });
                                }}
                                onChange={(e: any) => {
                                    setError(null);
                                    setBillingDetails({ ...billingDetails, city: e.target.value });
                                    if(validate("city",e.target.value.trim())) {
                                        setBillingErrors({ ...billingErrors, city: false });
                                    } else {
                                        setBillingErrors({ ...billingErrors, city: true });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        states.length > 0
                        &&
                        <Row className="g-3 FormRow">
                            <Col md="12">
                                <Row className="pt-1 pb-1">
                                    <Col md="2">
                                        <label className="form-label">{states.length > 0 ? "State*" : "State"}</label>
                                    </Col>
                                    <Col md="10" style={{ paddingLeft: "0" }}>
                                        <Select
                                            data-testid={`select_field_state`}
                                            id="state"
                                            placeholder="Select..."
                                            className=""
                                            ref={stateInputRef}
                                            options={states.length > 0 ? states : [{ value: "", label: "Select..." }]}
                                            required={states.length > 0}
                                            isDisabled={states.length === 0}
                                            onBlur={(e: any) => { }}
                                            onChange={(e: any) => {
                                                setError(null);
                                                if (e) {
                                                    setBillingDetails({ ...billingDetails, state: e.value });
                                                    setBillingErrors({ ...billingErrors, state: false });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    <Row className="g-3 FormRow">
                        <Col md="12">
                            <Field
                                label={areaCodeLabel}
                                id="postalCode"
                                type="text"
                                placeholder={areaCodeLabel.split('*')[0]}
                                required
                                labelClass={"col-md-2 form-label" + (billingErrors.postalCode ? " has-error" : "")}
                                inputClass={"col-md-10 form-control" + (billingErrors.postalCode ? " has-error" : "")}
                                value={billingDetails.postalCode}
                                onBlur={(e: any) => {
                                    setBillingDetails({ ...billingDetails, postalCode: e.target.value.trim() });
                                }}
                                onChange={(e: any) => {
                                    setError(null);
                                    setBillingDetails({ ...billingDetails, postalCode: e.target.value });
                                    if(validate("zip",e.target.value.trim())) {
                                        setBillingErrors({ ...billingErrors, postalCode: false });
                                    } else {
                                        setBillingErrors({ ...billingErrors, postalCode: true });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className='FormGroup'>
                    <label className={"col-md-4 form-label" + (billingErrors.postalCode ? " has-error" : "")}>Payment information*</label>
                    <div className='FormRow no-border'>
                        <CardElement
                            onChange={(e) => {
                                setError(e.error);
                                setCardComplete(e.complete);
                            }}
                            onBlur={() => {
                                setBillingErrors({ ...billingErrors, card: cardComplete });
                            }}
                        />
                    </div>
                </fieldset>
                <div className="mt-3">
                    Please complete all required(*) fields.
                </div>
                {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                {
                    hasErrors() || error
                        ?
                        <Fragment>
                            <Button className="SubmitButton" disabled={!stripe || hasErrors() || error}>
                                Pay USD ${currentHolds[0].price}
                            </Button>
                        </Fragment>
                        :
                        <SubmitButton processing={processing} error={error} disabled={!stripe || hasErrors()}>
                            Pay USD ${currentHolds[0].price}
                        </SubmitButton>
                }
            </form>
        </Fragment >
    );
};

export default CheckoutForm;
