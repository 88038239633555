import { useEffect, useRef } from 'react';
import { EventType } from '../@types/eventType.d';
import moment from 'moment';

export const checkEventsCutoff = (eventTypes: Array<EventType>) => {
    let filteredData: Array<EventType> = [];
    for (let i=0; i<eventTypes.length; i++) {
        //check if the event type has all with events that don't start till the cutoff date (35)
        if (eventTypes[i].events.every((e) => moment(Date.now()).add(35, "days") > moment(e.startDate))) {
            continue
        }
        else {
            filteredData.push(eventTypes[i]);
        }
    }
    return filteredData;
}