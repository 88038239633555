import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AppEmitter from "../../services/appEmitter";

import "./Header.css";
import UserToggle from "./UserToggle";

const Heading: React.FC = () => {
    const [showMobileMenu, setShowMobileMenu] = useState("");

    useEffect(() => {
        AppEmitter.on("SHOW_MOBILE_MENU", (value: Boolean) => {
            setShowMobileMenu(value as any);
        });
    }, []);

    return (
        <div id="kt_header" className="header flex-column header-fixed">
            <div className="header-top">
                <div className="container">
                    <div className="d-none d-lg-flex align-items-center mr-3">
                        <a target="_blank" href='https://www.ccl.org'>
                            <img alt="CCL" src="https://public-assets.ccl.org/images/logos/ccl/2-line/ccl-logo-color.svg" className="logo" />
                        </a>
                        {/* <ul className='header-tabs nav nav-dark align-self-end font-size-lg' role='tablist'>
                            {
                                menuItems.map((item, index) => (
                                    <li className='nav-item' key={index}>
                                        <Link
                                            data-testid={'web' + item.testId}
                                            to={item.target}
                                            className={'nav-link py-4 px-6 ' + (location.pathname === item.target ? 'active' : '')}>{item.title}</Link>
                                    </li>
                                ))
                            }
                        </ul> */}
                    </div>
                    <strong className="title">Registration Portal</strong>
                    <div className="topbar">
                        {
                            <Fragment>
                                {/* <Search /> */}
                                <UserToggle />
                            </Fragment>
                        }
                    </div>
                </div>
            </div>

            <div className="header-bottom d-flex d-lg-none">
                <div className="container">
                    <div className={"header-navs header-navs-left " + (showMobileMenu ? "header-navs-on" : "")} id="kt_header_navs">
                        <div className="tab-content">
                            <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                                {/* <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                    <ul className="menu-nav">
                                        {
                                            menuItems.map((item, index) => (
                                                <li className='menu-item' key={index}>
                                                    <Link
                                                        data-testid={'mobile' + item.testId}
                                                        to={item.target}
                                                        className={'menu-link'}
                                                        onClick={() => AppEmitter.emit('SHOW_MOBILE_MENU', false)}
                                                    >
                                                        <span className="menu-text">{item.title}</span>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Heading;
