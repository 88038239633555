import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Row  } from "react-bootstrap";

import "./UserAgreement.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
    onAgree?(e: any): void;
    agreed?: boolean;
}

const UserAgreement: React.FC<Props> = ({ onAgree, agreed }) => {
    const { agreementAccepted } = useSelector((state: RootState) => state.user);

    useEffect(()=>{
    },[agreementAccepted])

    return (
        <Fragment>
            <Card
                data-testid='display_event_card'
                className='card-custom'
                style={{marginBottom: agreed ? 40 : 120}}
            >
                <Card.Header>
                    <div className='noto card-title font-weight-bolder text-left'>
                        User Agreement
                    </div>
                </Card.Header>
                <Card.Body style={{padding: "0px 13.5px", marginBottom: 20}} className='text-left'>
                    <Row
                        data-testid='event_dates_row'
                        className={'noto'}
                        style={{padding: "8px 40px"}}
                    >
                        <p>
                            At GSK, we invest in developing our people. This programme will support you to gain the skills to live our culture and to unlock performance so that we can get ahead of disease together.
                        </p>
                        <p>
                            By enrolling in this programme you are agreeing to the following commitments:
                        </p>
                        <ul>
                            <li>I commit, by selecting this course date, that I will make all attempts to fully participate in the programme.</li>
                            <li>I commit, by selecting this course date, that I will make all attempts to read and engage with the pre-work prior to attending the programme experience.</li>
                            <li>I acknowledge that by requesting to cancel or transfer from this selected course date within 30 days before the start of this programme, I will accept any fees associated with this request.</li>
                        </ul>
                    </Row>
                    <Row style={{padding: "8px 40px"}}>
                        <div className="noto">
                            <div className="border p-5" style={{borderRadius: 10}}>
                                <Form.Check type="checkbox" checked={agreementAccepted || agreed} disabled={agreed} onChange={(e) => onAgree && onAgree(e.target.checked)} label="&nbsp;&nbsp;&nbsp;Check this box to confirm you have read and understood the commitments" />
                            </div>
                        </div>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>)
};

export default UserAgreement;
