export const getCountries = (): Array<any> => {
    return [
        { value: "DZ", id: "DZA", label: "Algeria" },
        { value: "AR", id: "ARG", label: "Argentina" },
        { value: "AU", id: "AUS", label: "Australia" },
        { value: "AT", id: "AUT", label: "Austria" },
        { value: "BH", id: "BHR", label: "Bahrain" },
        { value: "BD", id: "BGD", label: "Bangladesh" },
        { value: "BY", id: "BLR", label: "Belarus" },
        { value: "BE", id: "BEL", label: "Belgium" },
        { value: "BR", id: "BRA", label: "Brazil" },
        { value: "CA", id: "CAN", label: "Canada" },
        { value: "CL", id: "CHL", label: "Chile" },
        { value: "CN", id: "CHN", label: "China" },
        { value: "CO", id: "COL", label: "Colombia" },
        { value: "CR", id: "CRI", label: "Costa Rica" },
        { value: "HR", id: "HRV", label: "Croatia" },
        { value: "CZ", id: "CZE", label: "Czechia" },
        { value: "DK", id: "DNK", label: "Denmark" },
        { value: "DO", id: "DOM", label: "Dominican Republic" },
        { value: "EC", id: "ECU", label: "Ecuador" },
        { value: "EG", id: "EGY", label: "Egypt" },
        { value: "SV", id: "SLV", label: "El Salvador" },
        { value: "FI", id: "FIN", label: "Finland" },
        { value: "FR", id: "FRA", label: "France" },
        { value: "DE", id: "DEU", label: "Germany" },
        { value: "GR", id: "GRC", label: "Greece" },
        { value: "GT", id: "GTM", label: "Guatemala" },
        { value: "HN", id: "HND", label: "Honduras" },
        { value: "HK", id: "HKG", label: "Hong Kong" },
        { value: "HU", id: "HUN", label: "Hungary" },
        { value: "IN", id: "IND", label: "India" },
        { value: "ID", id: "IDN", label: "Indonesia" },
        { value: "IE", id: "IRL", label: "Ireland" },
        { value: "IL", id: "ISR", label: "Israel" },
        { value: "IT", id: "ITA", label: "Italy" },
        { value: "JM", id: "JAM", label: "Jamaica" },
        { value: "JP", id: "JPN", label: "Japan" },
        { value: "JO", id: "JOR", label: "Jordan" },
        { value: "KZ", id: "KAZ", label: "Kazakhstan" },
        { value: "KE", id: "KEN", label: "Kenya" },
        { value: "KR", id: "KOR", label: "Republic of Korea" },
        { value: "KW", id: "KWT", label: "Kuwait" },
        { value: "LB", id: "LBN", label: "Lebanon" },
        { value: "LT", id: "LTU", label: "Lithuania" },
        { value: "MO", id: "MAC", label: "Macao" },
        { value: "MY", id: "MYS", label: "Malaysia" },
        { value: "MU", id: "MUS", label: "Mauritius" },
        { value: "MX", id: "MEX", label: "Mexico" },
        { value: "MA", id: "MAR", label: "Morocco" },
        { value: "NL", id: "NLD", label: "Netherlands" },
        { value: "NZ", id: "NZL", label: "New Zealand" },
        { value: "NG", id: "NGA", label: "Nigeria" },
        { value: "NO", id: "NOR", label: "Norway" },
        { value: "OM", id: "OMN", label: "Oman" },
        { value: "PK", id: "PAK", label: "Pakistan" },
        { value: "PA", id: "PAN", label: "Panama" },
        { value: "PE", id: "PER", label: "Peru" },
        { value: "PH", id: "PHL", label: "Philippines" },
        { value: "PL", id: "POL", label: "Poland" },
        { value: "PT", id: "PRT", label: "Portugal" },
        { value: "QA", id: "QAT", label: "Qatar" },
        { value: "RO", id: "ROU", label: "Romania" },
        { value: "RU", id: "RUS", label: "Russian Federation" },
        { value: "SA", id: "SAU", label: "Saudi Arabia" },
        { value: "RS", id: "SRB", label: "Serbia" },
        { value: "SG", id: "SGP", label: "Singapore" },
        { value: "SK", id: "SVK", label: "Slovakia" },
        { value: "ZA", id: "ZAF", label: "South Africa" },
        { value: "ES", id: "ESP", label: "Spain" },
        { value: "LK", id: "LKA", label: "Sri Lanka" },
        { value: "SE", id: "SWE", label: "Sweden" },
        { value: "CH", id: "CHE", label: "Switzerland" },
        { value: "TW", id: "TWN", label: "Taiwan" },
        { value: "TH", id: "THA", label: "Thailand" },
        { value: "TT", id: "TTO", label: "Trinidad and Tobago" },
        { value: "TN", id: "TUN", label: "Tunisia" },
        { value: "TR", id: "TUR", label: "Turkey" },
        { value: "UA", id: "UKR", label: "Ukraine" },
        { value: "AE", id: "ARE", label: "United Arab Emirates" },
        { value: "GB", id: "GBR", label: "United Kingdom" },
        { value: "US", id: "USA", label: "United States of America" },
        { value: "UY", id: "URY", label: "Uruguay" },
        { value: "VN", id: "VNM", label: "Vietnam" },       
    ];
};

export const getStates = (country: string) => {
    if (country === "US") {
        return [
            { value: "AK", label: "Alaska" },
            { value: "AL", label: "Alabama" },
            { value: "AR", label: "Arkansas" },
            { value: "AS", label: "American Samoa" },
            { value: "AZ", label: "Arizona" },
            { value: "CA", label: "California" },
            { value: "CO", label: "Colorado" },
            { value: "CT", label: "Connecticut" },
            { value: "CZ", label: "Canal Zone" },
            { value: "DC", label: "District Of Columbia" },
            { value: "DE", label: "Delaware" },
            { value: "FL", label: "Florida" },
            { value: "GA", label: "Georgia" },
            { value: "GU", label: "Guam" },
            { value: "HI", label: "Hawaii" },
            { value: "IA", label: "Iowa" },
            { value: "ID", label: "Idaho" },
            { value: "IL", label: "Illinois" },
            { value: "IN", label: "Indiana" },
            { value: "KS", label: "Kansas" },
            { value: "KY", label: "Kentucky" },
            { value: "LA", label: "Louisiana" },
            { value: "MA", label: "Massachusetts" },
            { value: "MD", label: "Maryland" },
            { value: "ME", label: "Maine" },
            { value: "MI", label: "Michigan" },
            { value: "MN", label: "Minnesota" },
            { value: "MO", label: "Missouri" },
            { value: "MP", label: "Northern Mariana Islands" },
            { value: "MS", label: "Mississippi" },
            { value: "MT", label: "Montana" },
            { value: "NC", label: "North Carolina" },
            { value: "ND", label: "North Dakota" },
            { value: "NE", label: "Nebraska" },
            { value: "NH", label: "New Hampshire" },
            { value: "NJ", label: "New Jersey" },
            { value: "NM", label: "New Mexico" },
            { value: "NV", label: "Nevada" },
            { value: "NY", label: "New York" },
            { value: "OH", label: "Ohio" },
            { value: "OK", label: "Oklahoma" },
            { value: "OR", label: "Oregon" },
            { value: "PA", label: "Pennsylvania" },
            { value: "PR", label: "Puerto Rico" },
            { value: "RI", label: "Rhode Island" },
            { value: "SC", label: "South Carolina" },
            { value: "SD", label: "South Dakota" },
            { value: "TN", label: "Tennessee" },
            { value: "TT", label: "Trust Territories" },
            { value: "TX", label: "Texas" },
            { value: "UT", label: "Utah" },
            { value: "VA", label: "Virginia" },
            { value: "VI", label: "Virgin Islands" },
            { value: "VT", label: "Vermont" },
            { value: "WA", label: "Washington" },
            { value: "WI", label: "Wisconsin" },
            { value: "WV", label: "West Virginia" },
            { value: "WY", label: "Wyoming" },
            { value: "AP", label: "San Francisco - APO" },
            { value: "PW", label: "Palau Island" },
            { value: "FM", label: "Federated States Of Micronesia" },
            { value: "MH", label: "Marshall Islands" }
        ]
    } else if (country === "CA") {
        return [
            { value: "AB", label: "Alberta" },
            { value: "BC", label: "British Columbia" },
            { value: "MB", label: "Manitoba" },
            { value: "NB", label: "New Brunswick" },
            { value: "NF", label: "Newfoundland And Labrador" },
            { value: "NS", label: "Nova Scotia" },
            { value: "NT", label: "Northwest Territories" },
            { value: "ON", label: "Ontario" },
            { value: "PE", label: "Prince Edward Island" },
            { value: "QC", label: "Quebec" },
            { value: "SK", label: "Saskatchewan" },
            { value: "YT", label: "Yukon Territory" },
            { value: "NU", label: "Nunavut" },
        ];
    }
    return [];
};