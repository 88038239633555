import { Fragment, useState } from 'react';
import { Accordion, Card, Col } from 'react-bootstrap';
import moment from 'moment';
import { Event } from '../@types/event.d';
import { SkeletonCard } from '.';

interface EmailProps {
    event: Event;
}

const RegistrationEmailTemplate: React.FC<EmailProps> = ({event}) => {
    const [activeKey, setActiveKey] = useState<any>("1");

    return (
        <Fragment>
            { event ?
                <Fragment>
                    <Col xs="12">
                        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Card>
                                <Card.Header className='p-3'>
                                <Col xs="12" className="noto" 
                                // onClick={() => setActiveKey(activeKey === "1" ? "0" : "1")}
                                >
                                    We are pleased to confirm your enrollment in the GSK Leading Leaders Programme. <br/> 
                                    Congratulations on taking this exciting step! 
                                    We look forward to welcoming you in {event.city} on {moment(event.startDate).format('MMM DD, YYYY')}.&nbsp;
                                    {/* <a style={{ display: 'inline', textDecoration: 'underline' }} className="nav-link p-0">Click here to {activeKey === "0" ? 'know more!' : 'minimise.'}</a> */}
                                </Col>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <Col xs="12" className="noto">
                                    <strong>What's Next?:</strong><br/>
                                    In preparation for the start of the program, you will receive additional information in the coming days. We encourage you to review the schedule and any requirements before the first session.
                                    <ol>
                                        <li>
                                            <strong>Now:</strong> Receive and open Learning Platform access email from <a style={{ display: 'inline' }} className="nav-link p-0" href="mailto:support@ccl.org">support@ccl.org</a>, giving you access to logistics information, including programme overview, calendar holds, .pdf checklist, information about travel and accommodation. A single-sign on is used, so you will enter your company login on the sign-in page. You do not need to create an account. 
                                            <ul>
                                                <li>If you do not receive this email within 24 hours, please check your junk folder. If you are unable to find this email, reach out to&nbsp;
                                                    <a style={{ display: 'inline' }} href="mailto:GSK-CCLParticipantSupport@ccl.org" className="nav-link p-0">GSK-CCLParticipantSupport@ccl.org</a> with subject <strong>“{moment(event.startDate).format('MMM DD, YYYY')} - {event.city}: Learning Platform Invitation”</strong></li>
                                                <li>Be sure to bookmark</li>
                                            </ul> 
                                        </li>
                                        <li>
                                            <strong>4 weeks prior:</strong> 💻 Live virtual orientation session
                                            <ul>
                                                <li>Your calendar hold can be downloaded on the learning platform.</li>
                                                <li>An invitation from your producer will be sent 1 week prior to the orientation with the corresponding Teams link</li>
                                                <li>Virtual Orientation Follow Up summarizing information for those unable to attend</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>2 weeks prior</strong>
                                            <ul>
                                                <li>Detailed logistics email with information about shuttles, detailed venue information, and additional resources to ensure you are prepared for your session</li>
                                                <li>Hotel confirmation numbers will be shared from gsk.leading-leaders@gsk.com approximately two weeks before the program.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    If you have any questions, please feel free to contact our team at <a style={{ display: 'inline' }} href="mailto:GSK-CCLParticipantSupport@ccl.org" className="nav-link p-0">GSK-CCLParticipantSupport@ccl.org</a>. 
                                    </Col>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Fragment >
                :
                <Col xs="12">
                    <SkeletonCard />
                </Col>
            }
        </Fragment>
    );
};

export default RegistrationEmailTemplate;