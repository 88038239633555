import { Fragment } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '../components';
import Home from '../components/Home';
import Timeout from '../components/Timeout';

const Dashboard: React.FC = () => (
    <Fragment>
        <Home/>
        <Timeout/>
    </Fragment>
);

export default withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <Loading />
});
