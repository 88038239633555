import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Auth0Provider } from '@auth0/auth0-react';

import { store } from './store';
import MainPage from './MainPage';

import './App.css';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyCGSUkdYTN_UPmdSNQ4r7_y8-9piJ03vaw",
    authDomain: "registration-portal-e5ccd.firebaseapp.com",
    projectId: "registration-portal-e5ccd",
    storageBucket: "registration-portal-e5ccd.appspot.com",
    messagingSenderId: "799859510938",
    appId: "1:799859510938:web:8e93020932f6a0ff3dd923",
    measurementId: "G-RY2L5X6YS7"
  };
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  
  const[domain, setDomain] = useState<string>("");
  const[clientId, setClientId] = useState<string>("");
  const[appId, setAppId] = useState<string>("");
  const[tenId, setTenId] = useState<string>("");

  useEffect(()=> {
    setDomain(process.env.REACT_APP_AUTH0_DOMAIN as string);
    setClientId(process.env.REACT_APP_AUTH0_CLIENTID as string);
    setAppId(process.env.REACT_APP_GC_APPID as string);
    setTenId(process.env.REACT_APP_GC_TENID as string);
    
  },[])

  useEffect(() => {
    // if (domain) {
    //   logEvent(analytics, "Environment", {"domain": domain});
    // }
  },[domain])

  if (!domain || !clientId || !appId || !tenId) return null;
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      scope={`openid profile email loginApplicationId-${appId} tenantId-${tenId} signup-false`}
      redirectUri={window.location.origin}
    >
      <div className='App' data-testid='main-app-div'>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route component={MainPage} />
            </Switch>
          </Router>
        </Provider>
      </div>
    </Auth0Provider>
  );
}

export default App;
