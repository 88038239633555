// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { CheckoutForm } from '.';

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
        }
    ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEID as string);

interface StripeFormProps {
    onProcessing(): void;
    onSuccess(holdId: string): void;
    onError(): void;
    onComplete(): void;
    onCancel(): void;
}

const StripeForm: React.FC<StripeFormProps> = ({ onProcessing, onSuccess, onError, onComplete, onCancel }) => {
    return (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
                onProcessing={onProcessing}
                onSuccess={onSuccess}
                onError={onError}
                onComplete={onComplete}
                onCancel={onCancel}
            />
        </Elements>
    );
};

export default StripeForm;
